/* eslint-disable no-undef */
// heap.addEventProperties and addUserProperties are throwing eslint errors because heap is not defined in the file
// The recommendation is to replace heap with heapHandler, which references window.heap
// todo - test this change and implement if it works as expected
declare global {
  interface Window {
    heap: any
  }
}

const heapAnalytics = (heapObject: Object) => {
  if (typeof window !== 'undefined') {
    const heapHandler = window.heap
    let dataPending = true
    const heapTimeout = () => {
      if (typeof heapHandler !== 'undefined' && dataPending) {
        heapHandler.addEventProperties(heapObject)
        heapHandler.addUserProperties(heapObject)
      } else {
        setTimeout(heapTimeout, 1000)
      }
    }
    heapTimeout()
  }
}

export default heapAnalytics
